import * as React from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';

interface DemoEditorProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  copyButtonProps: {};
  id: string;
  language: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  value: string;
}

export default function DemoEditor(props: DemoEditorProps) {
  const { language, value, onChange, copyButtonProps, children, id, ...other } = props;

  // backgroundColor: "#f5f5f5",
  return (
      <div className="MuiCode-root">
        <div className="scrollContainer">
          <CodeEditor
            value={value}
            language="md"
            placeholder="Please enter in Markdown"
            onChange={onChange}
            padding={2}
            style={{
              fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
            }}
          />
        </div>
      </div>
  );
}
