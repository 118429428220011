import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import { db } from "../config/firebase";
import { type User } from 'firebase/auth'
import update from 'immutability-helper';
import { collection, doc, onSnapshot, setDoc} from "firebase/firestore";

import NoteSearch from './NoteSearch';
import Note from './Note';

interface ContentProps {
  userProfile: User | null;

}
interface INote {
  title: string;
  text: string;
  collapsed: boolean;
  isedit: boolean;
}

// a copy of task array is reversed before rendering, so taskIndex represents entry in reverse array
// get the actual task array index
function revIndex(tasks: INote[], taskIndex: number) {
  return tasks.length - taskIndex - 1;
}

export default function Content(props: ContentProps) {
  const [tasks, setTasks] = React.useState<Array<INote>>([{
     title: "Instruction",
     text: ""
    //  + "## Note taker\n\n"
    //  + "* Click Add note to add a new note\n"
    //  + "* Click checkbox to edit the formatted text\n"
    //  + "* You can also move note up, down and collapse/expand\n"
    //  + "* Also able to do search in the search bar\n"
    //  + "* The notes are saved in cloud when signed in so you can access from another machine\n"
    //  + "* Note taker doesn't use any information from profile other than email to associate note with the account in database\n"
    //  + "* The data is not shared with any third party\n"
     + "",
     collapsed: false, isedit: false }
  ]);

  React.useEffect(() => {
      if (props.userProfile == null) return;
      const email: string = props.userProfile.email as string
      const coll = collection(db, 'taskCollection');
      return onSnapshot(doc(coll, email), (snap) => {
        if (snap.metadata.hasPendingWrites) {
          // Not server change, ignore as we handle it before
          return
        }
        const docs = snap.data();
        if (docs !== undefined && "allnotes" in docs) {
          const notes = docs["allnotes"] as INote[];
          setTasks(notes)
        }
      });
  }, [props.userProfile]);

  function handleExpandClick(taskIndex: number) {
    taskIndex = revIndex(tasks, taskIndex);
    const state2 = update(tasks, { [taskIndex]: { $toggle: ['collapsed'] } });
    saveData(state2);
  }

  function handleEditor(taskIndex: number, checked: boolean) {
      taskIndex = revIndex(tasks, taskIndex)
      const state2 = update(tasks, { [taskIndex]: { isedit: { $set: checked} } });
     // const state2 = update(tasks, { [taskIndex]: { $toggle: ['isedit'] } });
      saveData(state2)
  }

  function onTitleUpdate(taskIndex: number, text: string) {
    taskIndex = revIndex(tasks, taskIndex)
    // e.target.value
    const state2 = update(tasks, { [taskIndex]: { title: { $set: text } } });
    saveData(state2)
  }

  function onTaskUpdate(taskIndex: number, text: string) {
    taskIndex = revIndex(tasks, taskIndex)
    const state2 = update(tasks, { [taskIndex]: { text: { $set: text } } });
    saveData(state2)
  }

  function saveData(notes: INote[]) {
    setTasks(notes);
    if (props.userProfile == null) {
      return
    };
    const email: string = props.userProfile.email as string;
    const collref = collection(db, 'taskCollection');
    const docref = doc(collref, email)
    setDoc(docref, { 'allnotes': notes }).catch(function (error) {
      console.error('Error writing new message to Firebase Database', error);
    });
  }

  function addTask() {
    const newtasks = update(tasks, { $push: [{ title: "Title", text: "", collapsed: false, isedit: true }] });
    saveData(newtasks)
  };

  function deleteTask(taskIndex: number) {
    if (tasks.length <= 1) return;
    taskIndex = revIndex(tasks, taskIndex)
    const newTasks = update(tasks, { $splice: [[taskIndex, 1]] });
    saveData(newTasks);
  };

  function moveDown(taskIndex: number) {
    taskIndex = revIndex(tasks, taskIndex)
    if (taskIndex === 0) return
    const item = tasks[taskIndex - 1]
    const newTasks = update(tasks, { $splice: [[taskIndex - 1, 1]] })
    const newTasks1 = update(newTasks, { $splice: [[taskIndex, 0, item]] })
    saveData(newTasks1)
  }

  function moveUp(taskIndex: number) {
    taskIndex = revIndex(tasks, taskIndex)
    if (taskIndex >= tasks.length - 1) return
    const item = tasks[taskIndex]
    const newTasks = update(tasks, { $splice: [[taskIndex, 1]] })
    const newTasks1 = update(newTasks, { $splice: [[taskIndex + 1, 0, item]] })
    saveData(newTasks1)
  }

  function stopPropagationTitle(index: number) {
    const taskIndex = revIndex(tasks, index)
    if (tasks[index].collapsed) {
        handleExpandClick(taskIndex)
    };
  }

  return (
    <Paper sx={{ /* maxWidth: 936, */ margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <SearchIcon color="inherit" sx={{ display: 'block' }} />
            </Grid>
            <Grid item xs>
                <NoteSearch tasks={tasks} onTitleClick = {stopPropagationTitle}/>
            </Grid>
            <Grid item>
              <Button variant="contained" sx={{ mr: 1 }} onClick={addTask}>
                Add note
              </Button>
              <Tooltip title="Reload">
                <IconButton>
                  <RefreshIcon color="inherit" sx={{ display: 'block' }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box sx={{ my: 5, mx: 2 }}>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Grid container spacing={16} alignItems="center" >
            <Grid item xs >
              {[...tasks].reverse().map((task, index) =>
                <Chip
                  label={task.title}
                  key={index}
                  color={task.collapsed ? "secondary" : "primary"}
                  onClick={() => handleExpandClick(index)}
                  variant="outlined"
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={16} margin="10" >
            <Grid item xs>
              {tasks.length > 0 && (
                [...tasks].reverse().map((task, index) =>
                  !task.collapsed && (
                    <Grid container spacing={8} margin="10" key={index} id={"iid-" + index}>
                      {/*Nested grid to do row, later may add multiple column per row*/}
                      <Grid item xs zeroMinWidth>
                        <Note
                              index = {index}
                              task = {task}
                              onTitleUpdate = {onTitleUpdate}
                              onToggleEdit = {handleEditor}
                              onMoveUp = {moveUp}
                              onMoveDown = {moveDown}
                              onDelete = {deleteTask}
                              handleExpand = {handleExpandClick}
                              onTaskUpdate = {onTaskUpdate}
                        />
                      </Grid>
                    </Grid>)
                )
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
