import * as React from 'react';
// import ReactMarkdown, { RuleType } from 'markdown-to-jsx';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';
// import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Markdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dracula} from 'react-syntax-highlighter/dist/esm/styles/prism'

import rehypeRaw from 'rehype-raw';

// import MDEditor from "@uiw/react-md-editor";
// import MarkdownPreview from '@uiw/react-markdown-preview';

// https://github.com/uiwjs/react-md-editor
// 
const options = {
  forceBlock: true,
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h4',
      },
    },
    h2: {
      component: Typography,
      props: { gutterBottom: true, variant: 'h6' },
    },
    h3: {
      component: Typography,
      props: { gutterBottom: true, variant: 'subtitle1' },
    },
    h4: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'caption',
        paragraph: true,
      },
    },
    p: {
      component: Typography,
      props: { paragraph: true },
    },
    a: { component: Link },
    li: {
      component: (props: any) => (
        <Box component="li" sx={{ mt: 1 }}>
          <Typography component="span" {...props} />
        </Box>
      ),
    },
  },

};

interface HighlightedMarkdownProps { 
  children: string;
}




export default function MyMarkdown(props: HighlightedMarkdownProps) {
  const {children} = props;
  return (
    <>
      <div className="container" >
      <Markdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        code({ node, inline, className, children, ...props }: any) {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter style={dracula} PreTag="div" language={match[1]} {...props}>
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
        img:({node,...props})=><img style={{maxWidth:'100%'}}{...props}/>
      }}
      >
            {children}
      </Markdown>
      </div>
    </>
  );
}
